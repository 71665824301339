<template>
  <div
    class="dropdown"
    v-click-outside="closeDropdown"
    :class="{ 'dropdown--active': isDropdownOpen }"
  >
    <div @click="closeDropdown" v-if="isDropdownOpen" class="overlay"></div>
    <div class="dropdown__container">
      <div class="dropdown__input-field" @click="toggleDropdownVisibility">
        <input disabled :value="selectedValueString" type="number" />
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M14.2929 6.79289C14.6834 6.40237 15.3166 6.40237 15.7071 6.79289C16.0976 7.18342 16.0976 7.81658 15.7071 8.20711L14.2929 6.79289ZM10 12.5L10.7071 13.2071L10 13.9142L9.29289 13.2071L10 12.5ZM4.29289 8.20711C3.90237 7.81658 3.90237 7.18342 4.29289 6.79289C4.68342 6.40237 5.31658 6.40237 5.70711 6.79289L4.29289 8.20711ZM15.7071 8.20711L10.7071 13.2071L9.29289 11.7929L14.2929 6.79289L15.7071 8.20711ZM9.29289 13.2071L4.29289 8.20711L5.70711 6.79289L10.7071 11.7929L9.29289 13.2071Z"
            fill="#7A939C"
          />
        </svg>
      </div>
      <div v-if="isDropdownOpen" class="dropdown__data">
        <div class="dropdown-header">
          <span class="dropdown-header__title">
            {{
              $t(
                "referral_registration.my_referrals.pagination.per_page_count.choose_referral_per_page"
              )
            }}
          </span>
          <svg
            @click="closeDropdown"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M6.33621 6.33621C5.88793 6.78448 5.88793 7.51128 6.33621 7.95955L10.3769 12.0002L6.33648 16.0406C5.8882 16.4889 5.8882 17.2157 6.33648 17.664C6.78475 18.1122 7.51155 18.1122 7.95982 17.664L12.0002 13.6236L16.0398 17.6631C16.488 18.1114 17.2148 18.1114 17.6631 17.6631C18.1114 17.2148 18.1114 16.488 17.6631 16.0398L13.6236 12.0002L17.6641 7.95973C18.1123 7.51145 18.1123 6.78465 17.6641 6.33638C17.2158 5.8881 16.489 5.8881 16.0407 6.33638L12.0002 10.3769L7.95956 6.33621C7.51128 5.88793 6.78448 5.88793 6.33621 6.33621Z"
              fill="#B7C9D3"
            />
          </svg>
        </div>
        <ul class="data-list">
          <template v-for="item of dataList">
            <li
              @click="selectItemHandler(item)"
              :key="item"
              class="data-list__item"
            >
              <span>{{ item }}</span>
            </li>
          </template>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ReferralsPerPageDropdown",
  props: {
    selectedValue: {
      type: [Number, String],
      required: true,
    },
    dataList: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      isDropdownOpen: false,
    };
  },
  computed: {
    selectedValueString() {
      return this.selectedValue.toString();
    },
  },
  methods: {
    toggleDropdownVisibility() {
      this.isDropdownOpen = !this.isDropdownOpen;
    },
    closeDropdown() {
      this.isDropdownOpen = false;
    },
    selectItemHandler(item) {
      this.$emit("select", item);
      this.closeDropdown();
    },
  },
};
</script>

<style scoped lang="scss">
@import "~@/assets/scss/_variables.scss";

.dropdown {
  height: 100%;

  .overlay {
    display: none;
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    background-color: rgba(51, 63, 72, 0.2);
    z-index: 2;
  }

  &-header {
    display: none;
    grid-auto-flow: column;
    column-gap: 1.3125rem;
    align-items: center;
    margin-bottom: 0.75rem;
    padding: 0.75rem 0.5rem 0.75rem 1rem;
  }

  &__input-field {
    position: relative;
    display: flex;
    align-items: center;
    border-bottom: 1px solid $gray3;
    padding-bottom: 0.25rem;
    width: 3rem;

    cursor: pointer;

    input {
      font-family: "Rubik", sans-serif;
      border: none;
      padding: 0;
      width: 100%;
      font-weight: 500;
      font-size: 1rem;
      line-height: 1.5rem;
      color: $mainBlack;
      background: transparent;
    }
  }

  &__data {
    position: absolute;
    bottom: calc(100% + 0.5625rem);
    z-index: 2;
    left: 0;
    box-shadow: 0 0 62px rgba(183, 201, 211, 0.5);
    border-radius: 8px;
    background: #ffffff;
    padding: 0.5rem;
  }

  .data-list {
    margin: 0;
    display: grid;
    grid-auto-flow: row;
    row-gap: 0.5rem;
    width: 10rem;
    list-style: none;
    padding: 0;

    &__item {
      width: 100%;
      padding: 0.9375rem 1rem;
      border-bottom: 1px solid $dividersGray;

      span {
        font-family: "Rubik", sans-serif;
        font-weight: 400;
        font-size: 1rem;
        line-height: 1.5rem;
        color: $mainBlack;
      }

      &:hover {
        border-radius: 0.5rem;
        cursor: pointer;
        background: $grayLight;
        border-bottom: 1px solid transparent;
      }
    }
  }
}

@media screen and (max-width: 486px) {
  .dropdown {
    display: flex;
    align-items: center;

    &--active {
      .overlay {
        display: block;
      }
    }

    &-header {
      display: grid;

      &__title {
        font-weight: 700;
        font-size: 1rem;
        line-height: 1.5rem;
        color: $mainBlack;
      }

      svg {
        cursor: pointer;
      }
    }

    &__data {
      position: fixed;
      width: 100vw;
      bottom: 0;
      border-radius: 1.5rem 1.5rem 0 0;
      padding-top: 0.0625rem;
      z-index: 3;
    }

    .data-list {
      width: 100%;

      &__item {
        margin-bottom: 0.25rem;
        position: relative;

        &::after {
          content: "";
          position: absolute;
          left: 0;
          bottom: -0.25rem;
          height: 0.0625rem;
          width: 100%;
          background: $mainColdHover;
        }
      }
    }
  }
}
</style>
