<template>
  <div class="payments">
    <ul class="payments-list">
      <template v-for="(paymentItem, index) of paymentsData">
        <li :key="index" class="payments-list__item">
          <payment-block
            :value="`${paymentItem.value} ${paymentItem.currency || ''}`"
            :description="
              $t(paymentItem.description, paymentItem.descriptionData || {})
            "
            :tooltip="$t(paymentItem.tooltip, paymentItem.tooltipData || {})"
            :imagePath="paymentItem.logoPath"
            :index="index"
          />
        </li>
      </template>
    </ul>
  </div>
</template>

<script>
import PaymentBlock from "@/components/referral/PaymentBlock";

export default {
  name: "PaymentsSection",
  components: {
    PaymentBlock,
  },
  props: {
    paymentsData: {
      type: [Array, Object],
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/_mixins.scss";

.payments {
  background-color: $gray3;
  border-radius: 1.5rem;
  padding: 2.5rem 2.5rem 2.75rem;
  --block-gap: 0.5rem;

  &-list {
    list-style: none;
    padding: 0;
    margin: 0;

    display: grid;
    grid-template-columns: repeat(3, 1fr);
    justify-content: space-between;

    &__item {
      &:not(:last-child) {
        padding-right: 1.5rem;
      }

      &:not(:first-child) {
        padding-left: 2.5rem;
        position: relative;

        &:after {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          height: 100%;
          width: 1px;
          background-color: $gray2;
        }
      }
    }
  }
}

@media screen and (max-width: 486px) {
  .payments {
    padding: 1rem 1.5rem;
    &-list {
      grid-template-columns: 1fr;
      grid-auto-flow: row;
      row-gap: calc(var(--block-gap) * 2);

      &__item {
        &:not(:last-child) {
          padding-right: 0;
        }

        &:not(:first-child) {
          padding-left: 0;

          &:after {
            top: unset;
            left: 0;
            bottom: calc(100% + var(--block-gap));
            height: 1px;
            width: 100%;
          }
        }
      }
    }
  }
}
</style>
