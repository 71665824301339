<template>
  <div
    class="referral-table"
    :class="{ 'referral-table--no-referrals': !isReferralsExist }"
  >
    <div class="referral-table__title table-title">
      <span class="table-title__text"
        >{{ $t("referral_registration.my_referrals.title") }}
      </span>
    </div>
    <template v-if="!isReferralsExist">
      <referral-table-empty />
    </template>
    <template v-else>
      <div
        class="referral-table__content table-content"
        :class="{ 'table-content--hide-pagination': !isPaginationVisible }"
      >
        <referral-table-header :total-referrals-count="countReferrals" />
        <referral-table-users-list
          @referralTableScroll="referralTableScrollHandler"
          :referrals-list="referralsList"
        />
        <referral-table-pagination
          v-show="isPaginationVisible"
          @selectReferralsPerPage="selectReferralsPerPageHandler"
          @changePage="changePageHandler"
          :current-page="currentPage"
          :selected-referrals-per-page="selectedReferralsPerPage"
          :referrals-per-page-data-list="referralsPerPageData"
          :total-items-count="countReferrals"
        />
      </div>
    </template>
  </div>
</template>

<script>
import ReferralTableEmpty from "@/components/referral/referralsTable/ReferralTableEmpty";
import ReferralTableUsersList from "@/components/referral/referralsTable/ReferralTableUsersList";
import ReferralTablePagination from "@/components/referral/referralsTable/pagination/ReferralTablePagination";
import ReferralTableHeader from "@/components/referral/referralsTable/ReferralTableHeader";

export default {
  name: "ReferralTable",
  components: {
    ReferralTableHeader,
    ReferralTablePagination,
    ReferralTableUsersList,
    ReferralTableEmpty,
  },
  props: {
    referralsList: {
      type: Array,
      default: () => [],
    },
    countPages: {
      type: Number,
      default: 1,
    },
    countReferrals: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {
      isPaginationVisible: true,
      currentPage: 1,
      selectedReferralsPerPage: 5,
      referralsPerPageData: [5, 10, 20, 50],
    };
  },
  computed: {
    isReferralsExist() {
      return this.referralsList?.length > 0;
    },
  },
  methods: {
    referralTableScrollHandler(event) {
      const scrollTop = event.scrollTop;
      this.$emit("referralTableScroll", scrollTop);
      this.isPaginationVisible = scrollTop < 100;
    },
    selectReferralsPerPageHandler(value) {
      if (this.referralsPerPageData.includes(value)) {
        this.selectedReferralsPerPage = value;
        this.$emit("paginationChanged", {
          currentPage: this.currentPage,
          perPage: this.selectedReferralsPerPage,
        });
      }
    },
    changePageHandler(page) {
      this.currentPage = page;
      this.$emit("paginationChanged", {
        currentPage: this.currentPage,
        perPage: this.selectedReferralsPerPage,
      });
    },
  },
  watch: {
    selectedReferralsPerPage() {
      this.currentPage = 1;
    },
  },
};
</script>

<style scoped lang="scss">
@import "~@/assets/scss/_variables.scss";

.referral-table {
  --header-height: 6rem;
  --table-title-height: 4.5rem;
  --table-footer-height: 5rem;

  display: grid;
  grid-template-rows: var(--header-height) 1fr;
  height: 100%;
  overflow: hidden;
  background: #ffffff;
  border-radius: 1.5rem;

  padding: 0 2.5rem;

  &--no-referrals {
    .table-title {
      border-bottom: none;
    }
  }

  &__title {
    padding: 2.5rem 0 1.5rem;
  }
}

.table-title {
  box-sizing: border-box;
  border-radius: 1.5rem 1.5rem 0 0;
  border-bottom: 1px solid $gray3;

  &--no-referrals {
    opacity: 0.5;
  }

  &__text {
    font-family: "Rubik", sans-serif;
    font-weight: 700;
    font-size: 1.5rem;
    line-height: 2rem;
    color: $mainBlack;
  }
}

.table-content {
  height: 100%;
  overflow: hidden;
  display: grid;
  grid-template-rows: var(--table-title-height) 1fr var(--table-footer-height) /* 80/16 */;

  &--hide-pagination {
    grid-template-rows: 6rem 1fr;
  }
}

@media screen and (max-width: 486px) {
  .referral-table {
    --table-title-height: 3.5rem;
    --table-footer-height: 9.0625rem;
    border-radius: 1.5rem 1.5rem 0 0;

    padding: 0 1rem;

    &__title {
      padding-top: 2.5rem;
      padding-bottom: 1rem;
    }
  }
  .table-content {
    align-items: center;

    &--hide-pagination {
      grid-template-rows: 4.3125rem 1fr;
    }
  }
  .table-title {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &__text {
      font-size: 1.25rem;
    }
  }
}
</style>
