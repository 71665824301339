<template>
  <div class="table-header">
    <div class="table-header__col header-col">
      <div class="header-col__title">
        <span>
          {{ $t("referral_registration.my_referrals.table.header.identifier") }}
        </span>
      </div>
      <div class="header-col__value">
        <span>
          {{ totalReferralsCount }}
        </span>
      </div>
    </div>
    <div class="table-header__col header-col">
      <div class="header-col__title">
        <span>
          {{ $t("referral_registration.my_referrals.table.header.date") }}
        </span>
      </div>
    </div>
    <div class="table-header__col header-col">
      <div class="header-col__title"></div>
    </div>
    <div class="table-header__col header-col">
      <div class="header-col__title">
        <span>
          {{
            $t("referral_registration.my_referrals.table.header.current-status")
          }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ReferralTableHeader",
  props: {
    totalReferralsCount: {
      type: Number,
      required: true,
    },
  },
};
</script>

<style scoped lang="scss">
@import "~@/assets/scss/_variables.scss";
@import "tableGrid.mixin";

.table-header {
  height: 100%;
  @include table-grid;

  &__col {
    @include colBorder;

    &:first-child {
      justify-content: flex-start;
      text-align: left;
    }
  }
}

.header-sublist {
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: 1fr;

  .header-col {
    padding: 0;
  }

  &__item {
    border-left: 1px dashed $gray2;
  }
}

.header-col {
  display: grid;
  grid-auto-flow: column;
  column-gap: 0.625rem;
  align-items: center;
  justify-content: center;
  text-align: center;

  &__tooltip {
    font-weight: 500;
    font-size: 0.75rem;
    line-height: 1rem;
    color: $gray1;
  }

  &__title,
  &__value {
    font-size: 1rem /* 16/16 */;
    line-height: 1.5rem /* 24/16 */;
  }

  &__title {
    color: $gray1;
    font-weight: 400;
  }

  &__value {
    font-weight: 700;
  }
}

@media screen and (max-width: 486px) {
  .table-header {
    grid-template-columns: repeat(2, 1fr);

    &__col {
      &:nth-child(2),
      &:nth-child(3) {
        display: none;
      }

      &:last-child {
        justify-content: flex-end;
      }
    }
  }
  .header-col {
    padding: 0;
    width: 100%;
    display: inline-flex;
    align-items: center;

    &:nth-child(3) {
      justify-content: center;
    }

    text-align: center;

    &__title {
      font-size: 0.875rem;
      line-height: 1.125rem;
    }
  }
}
</style>
