<template>
  <div class="referral-tasks">
    <div
      class="referral-tasks__item"
      :style="styleObj"
      :class="{ complete: task.status === 'complete' }"
      :key="`${accountId}-task-${task.referral_task_slug}`"
      v-for="task in tasks"
    ></div>
  </div>
</template>

<script>
export default {
  name: "ReferralItemStatusSteps",
  props: {
    accountId: {
      type: String,
      required: true,
    },
    tasks: {
      type: [Object, Array],
      required: true,
    },
  },
  computed: {
    styleObj() {
      console.log({
        width: `calc(${100 / this.tasks.length}% - ${
          ((this.tasks.length - 1) * 6) / this.tasks.length
        })px`,
      });
      return {
        width: `calc(${100 / this.tasks.length}% - ${
          ((this.tasks.length - 1) * 6) / this.tasks.length
        }px)`,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/_variables.scss";
.referral-tasks {
  padding: 0 24px;
  width: 100%;
  height: 100%;

  display: flex;
  align-items: center;
  justify-content: space-between;

  &__item {
    height: 6px;
    border-radius: 4px;
    background-color: $mainColdLight;

    &.complete {
      background-color: $mainCold;
    }
  }
}
</style>
