<template>
  <div class="table-navigation">
    <svg
      @click="prevPage"
      class="table-navigation__arrow"
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M15.6847 19.1866C15.9776 18.8937 15.9776 18.4188 15.6847 18.1259L10.2151 12.6562L15.6847 7.18658C15.9776 6.89369 15.9776 6.41881 15.6847 6.12592C15.3918 5.83303 14.917 5.83303 14.6241 6.12592L8.09375 12.6562L14.6241 19.1866C14.917 19.4795 15.3918 19.4795 15.6847 19.1866Z"
        fill="#7A939C"
      />
    </svg>
    <ul class="table-navigation__pages table-pages">
      <template v-for="(page, index) of pagesList">
        <li
          @click="goToPage(page)"
          :key="index"
          class="table-pages__item"
          :class="{ 'table-pages__item--current': page === currentPage }"
        >
          {{ page }}
        </li>
      </template>
    </ul>
    <svg
      @click="nextPage"
      class="table-navigation__arrow"
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8.31526 19.1866C8.02237 18.8937 8.02237 18.4188 8.31526 18.1259L13.7849 12.6562L8.31526 7.18658C8.02237 6.89369 8.02237 6.41881 8.31526 6.12592C8.60815 5.83303 9.08303 5.83303 9.37592 6.12592L15.9063 12.6562L9.37592 19.1866C9.08303 19.4795 8.60815 19.4795 8.31526 19.1866Z"
        fill="#7A939C"
      />
    </svg>
  </div>
</template>

<script>
function getPaginationGenerator(
  currentPageNumber,
  totalPageNumber,
  offset = 2,
  gap = "..."
) {
  const offsetNumber =
    currentPageNumber <= offset || currentPageNumber > totalPageNumber - offset
      ? offset
      : offset - 1;
  const numbersList = [];
  const numbersListWithDots = [];
  if (totalPageNumber <= 1 || totalPageNumber === undefined) return [1];
  numbersList.push(1);
  for (
    let i = currentPageNumber - offsetNumber;
    i <= currentPageNumber + offsetNumber;
    i++
  ) {
    if (i < totalPageNumber && i > 1) {
      numbersList.push(i);
    }
  }
  numbersList.push(totalPageNumber);

  numbersList.reduce((accumulator, currentValue) => {
    if (accumulator === 1) {
      numbersListWithDots.push(accumulator);
    }
    if (currentValue - accumulator !== 1) {
      numbersListWithDots.push(gap);
    }
    numbersListWithDots.push(currentValue);

    return currentValue;
  });

  return numbersListWithDots;
}

export default {
  name: "ReferralTableNavigation",
  props: {
    referralsPerPage: {
      type: Number,
      required: true,
    },
    currentPage: {
      type: Number,
      required: true,
    },
    totalItemsCount: {
      type: Number,
      required: true,
    },
  },
  computed: {
    totalPages() {
      return Math.ceil(this.totalItemsCount / this.referralsPerPage);
    },
    pagesList() {
      return getPaginationGenerator(this.currentPage, this.totalPages);
    },
  },
  methods: {
    goToPage(page) {
      if (typeof page !== "number") return;
      this.$emit("changePage", page);
    },
    prevPage() {
      if (this.currentPage === 1) {
        return;
      }

      this.goToPage(this.currentPage - 1);
    },
    nextPage() {
      if (this.currentPage === this.totalPages) {
        return;
      }
      this.goToPage(this.currentPage + 1);
    },
  },
};
</script>

<style scoped lang="scss">
@import "~@/assets/scss/_variables.scss";

.table-navigation {
  display: grid;
  grid-auto-flow: column;
  column-gap: 1.5rem;
  align-items: center;

  &__pages {
    margin: 0;
    padding: 0;
    list-style: none;
  }
  &__arrow {
    cursor: pointer;
  }
}

.table-pages {
  display: grid;
  grid-auto-flow: column;
  column-gap: 1rem;
  align-items: center;
  width: fit-content;
  &__item {
    font-family: "Rubik", sans-serif;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.5rem;
    color: $gray1;
    cursor: pointer;
    &--current {
      font-weight: 600;
      color: $mainBlack;
    }
  }
}

@media screen and (max-width: 486px) {
  .table-navigation {
    display: flex;
    justify-content: space-between;
  }
}
</style>
