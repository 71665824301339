<template>
  <div
    :style="{ height: pageHeight }"
    ref="myReferrals"
    class="my-referrals"
    :class="{ 'my-referrals--empty': !referralsList.length }"
  >
    <base-loader v-if="isDataLoading"></base-loader>
    <referral-table
      @referralTableScroll="referralTableScrollHandler"
      :referrals-list="referralsList"
      :count-pages="countPages"
      :count-referrals="countReferrals"
      @paginationChanged="handlePaginationChanged"
    />
  </div>
</template>

<script>
import ReferralTable from "@/components/referral/referralsTable/ReferralTable";
import { ReferralsApi } from "@/office/referrals-api";
import BaseLoader from "@/components/base/BaseLoader";

export default {
  name: "ReferralsList",
  components: { BaseLoader, ReferralTable },
  data() {
    return {
      referralsList: [],
      currentPage: 1,
      countPages: 1,
      perPage: 5,
      countReferrals: 1,
      pageHeight: "",
      minPageHeight: 600,
      isDataLoading: false,
    };
  },
  async created() {
    await this.loadReferralsList();
  },
  methods: {
    async loadReferralsList() {
      this.isDataLoading = true;
      try {
        const { users, count_pages, count_users } = (
          await ReferralsApi.getReferrals(this.currentPage, this.perPage)
        ).data;

        this.referralsList = users;
        this.countPages = count_pages;
        this.countReferrals = count_users;
      } catch (e) {
        console.error(
          "Something went wrong when loading referrals list: \n",
          e
        );
      }

      this.isDataLoading = false;
    },
    calcPageHeight() {
      const calculatedHeight =
        window.innerHeight - this.$refs.myReferrals.getBoundingClientRect().top;
      this.pageHeight =
        (calculatedHeight < this.minPageHeight
          ? this.minPageHeight
          : calculatedHeight) + "px";
    },
    resizeHandler() {
      this.calcPageHeight();
    },
    referralTableScrollHandler(scrollTop) {
      this.$refs.myReferrals.style.padding = scrollTop >= 100 ? "0" : "";
      this.calcPageHeight();
    },
    handlePaginationChanged(paginationObject) {
      this.currentPage =
        this.perPage !== paginationObject.perPage
          ? 1
          : paginationObject.currentPage;
      this.perPage = paginationObject.perPage;

      this.loadReferralsList();
    },
  },
  mounted() {
    this.calcPageHeight();
    window.addEventListener("resize", this.resizeHandler);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.resizeHandler);
  },
};
</script>

<style lang="scss">
body {
  &::-webkit-scrollbar {
    width: 0;
  }
}
</style>
<style scoped lang="scss">
.my-referrals {
  position: relative;
  padding-bottom: 2.5rem;
}

@media screen and (max-width: 486px) {
  .my-referrals {
    margin: 0 -1rem;
    padding-bottom: 4.125rem;

    &:not(&--empty) {
      min-height: 910px;
    }
  }
}
</style>
