<template>
  <div class="link-block">
    <link-field @copyLink="copyLinkHandler" :link="referralLink" />
    <div class="share-block">
      <div class="share-block__top">
        <span>{{ $t("popup_end_referral.titleMob") }}</span>
      </div>
      <social-list />
    </div>
  </div>
</template>

<script>
import LinkField from "@/components/referral/terms/shareLink/LinkField";
import CopyReferralLink from "@/mixins/copy-referral-link";
import SocialList from "@/components/referral/Popup/SocialList";

export default {
  name: "ShareReferralLink",
  components: {
    SocialList,
    LinkField,
  },
  mixins: [CopyReferralLink],
  props: {
    referralLink: {
      type: String,
      required: true,
    },
  },
};
</script>

<style scoped lang="scss">
@import "~@/assets/scss/_variables.scss";

.link-block {
  min-height: 3rem;
  display: grid;
  grid-template-rows: 2.5625rem 1fr;
  row-gap: 1rem;
}

.share-block {
  &__top {
    height: 1.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    margin-bottom: 1.25rem;

    &::before {
      content: "";
      position: absolute;
      left: 0;
      top: calc(50% - 1px);
      height: 1px;
      width: 100%;
      background: $dividersGray;
    }

    span {
      display: flex;
      background: #ffffff;
      font-family: "Nunito", sans-serif;
      font-weight: 400;
      font-size: 0.75rem;
      line-height: normal;
      color: $gray1;
      z-index: 2;
      padding: 0 1.5rem;
    }
  }
}

@media screen and (max-width: 486px) {
  .link-block {
    grid-template-columns: none;
    min-height: auto;
    row-gap: 1.25rem;
  }
}
</style>
