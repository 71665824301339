<template>
  <div class="empty-table">
    <div class="empty-table__content table-content">
      <div class="table-content__icon">
        <img
          src="@/assets/img/referral-table-empty.png"
          alt="$t('referral_registration.my_referrals.empty_state.title')"
        />
      </div>
      <div class="table-content__text text-content">
        <div class="text-content__title">
          {{ $t("referral_registration.my_referrals.empty_state.title") }}
        </div>
        <div class="text-content__subtitle">
          {{ $t("referral_registration.my_referrals.empty_state.subtitle") }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ReferralTableEmpty",
};
</script>

<style scoped lang="scss">
@import "~@/assets/scss/_variables.scss";

.empty-table {
  display: grid;
  grid-auto-flow: row;
  row-gap: 3rem;
  align-items: center;
  justify-content: center;
  padding-bottom: 5rem;
}

.table-content {
  display: grid;
  grid-auto-flow: row;
  row-gap: 2rem;
  justify-items: center;
  height: fit-content;

  &__icon {
    img {
      width: 88px;
      height: 88px;
    }
  }
}

.text-content {
  text-align: center;
  display: grid;
  grid-auto-flow: row;
  row-gap: 0.5rem;
  max-width: 23.6875rem;

  &__title {
    font-family: "Rubik", sans-serif;
    font-weight: 600;
    font-size: 1.25rem;
    line-height: 1.75rem;
    color: $mainBlack;
  }

  &__subtitle {
    font-family: "Nunito", sans-serif;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.5rem;
    color: $gray1;
  }
}
</style>
