<template>
  <div class="withdrawal-modal popup">
    <div class="withdrawal-modal__container popup__block">
      <div class="withdrawal-modal__top withdrawal-top">
        <div class="withdrawal-top__title">
          {{ $t("referral_withdrawal_modal.title") }}
        </div>
        <div class="withdrawal-top__close">
          <svg
            @click="closeModal"
            width="25"
            height="24"
            viewBox="0 0 25 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M5.5 5L19.5 19"
              stroke="#7A939C"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M19.5 5L5.5 19"
              stroke="#7A939C"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </div>
      </div>
      <div class="withdrawal-modal__content withdrawal-content">
        <div class="withdrawal-content__text">
          {{ $t("referral_withdrawal_modal.text") }}
        </div>
        <div class="withdrawal-content__sum withdrawal-sum">
          <div class="withdrawal-sum__text">
            {{ $t("referral_withdrawal_modal.amount") }}
          </div>
          <div class="withdrawal-sum__amount">{{ amountText }}</div>
        </div>
      </div>
      <div class="withdrawal-modal__action withdrawal-action">
        <primary-colored-button @click="confirmWithdrawal">
          {{ $t("referral_withdrawal_modal.action") }}
        </primary-colored-button>
        <button
          @click="closeModal"
          class="withdrawal-action__close-button"
          type="button"
        >
          {{ $t("referral_withdrawal_modal.cancel") }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import PrimaryColoredButton from "@/components/button/PrimaryColoredButton";

export default {
  name: "ReferralWithdrawalModal",
  components: { PrimaryColoredButton },
  props: {
    amount: {
      type: [String, Number],
      required: true,
    },
    currency: {
      type: String,
      default: "USDT",
    },
  },
  computed: {
    amountText() {
      return `${this.amount} ${this.currency}`;
    },
  },
  methods: {
    confirmWithdrawal() {
      this.$emit("confirmWithdrawal");
    },
    closeModal() {
      this.$emit("closeModal");
    },
  },
};
</script>

<style scoped lang="scss">
@import "~@/assets/scss/_variables.scss";

.withdrawal-top__title,
.withdrawal-sum__text,
.withdrawal-sum__amount,
.withdrawal-action__close-button {
  font-family: "Rubik", sans-serif;
}

.withdrawal-content__text,
.withdrawal-sum__amount,
.withdrawal-sum__text,
.withdrawal-action__close-button {
  font-size: 1rem;
  line-height: 1.5rem;
}

.withdrawal-top__title,
.withdrawal-content__text,
.withdrawal-sum__amount {
  color: $mainBlack;
}

.withdrawal-modal {
  &__container {
    max-width: 28.1875rem;
  }

  &__top {
    margin-bottom: 2rem;
  }

  &__content {
    margin-bottom: 2.5rem;

    padding: 1.5rem 1.5rem 2.5rem;
    background: $grayLight;
    border-radius: 8px;
  }
}

.withdrawal-top {
  display: grid;
  grid-auto-flow: column;
  align-items: flex-start;
  column-gap: 1rem;

  &__title {
    font-weight: 700;
    font-size: 1.5rem;
    line-height: 2rem;
  }

  &__close {
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      cursor: pointer;
    }
  }
}

.withdrawal-content {
  display: grid;
  grid-auto-flow: row;
  row-gap: 2rem;

  &__text {
    font-family: "Nunito", "Rubik", sans-serif;
    font-weight: 400;
  }

  &__sum {
  }
}

.withdrawal-sum {
  display: flex;
  align-items: center;
  justify-content: space-between;

  &__text {
    font-weight: 400;
    color: $gray1;
  }

  &__amount {
    font-weight: 600;
  }
}

.withdrawal-action {
  display: grid;
  grid-auto-flow: row;
  row-gap: 1.75rem;
  text-align: center;

  &__close-button {
    display: none;
    background: transparent;
    border: none;
    padding: 0;
    margin: 0;

    font-weight: 600;
    color: $mainCold;
    cursor: pointer;
  }
}

@media screen and (max-width: 486px) {
  .withdrawal-modal {
    &__container {
      max-width: 100%;
      display: flex;
      flex-direction: column;
      padding-bottom: 3.125rem;
    }
  }
  .withdrawal-top {
    margin-bottom: 1.5rem;

    &__title {
      text-align: center;
      font-weight: 600;
      font-size: 1.25rem;
      line-height: 1.75rem;
    }

    &__close {
      display: none;
    }
  }
  .withdrawal-content {
    &__text {
    }
  }
  .withdrawal-action {
    margin-top: auto;
    &__close-button {
      display: block;
    }
  }
}
</style>
