import { shareLinks } from "@/const/referrals";

const titleReplaceKey = "$TITLE";
const urlReplaceKey = "$URL";

/**
 * @typedef SocialShareData
 * @property {string} url
 * @property {string | undefined} text
 */

/**
 * @class SocialMediaShareService
 */
class SocialMediaShareService {
  /**
   * @param {{baseUrl: string, url: string, text: string}} options
   * @returns {string}
   * @private
   */
  _getFullShareLink(options) {
    const { url, text, baseUrl } = options;
    return baseUrl
      .replace(urlReplaceKey, encodeURI(url))
      .replace(titleReplaceKey, text);
  }

  /**
   * @param {{socialName: string, shareData: SocialShareData}} options
   */
  shareData(options) {
    if (!options) {
      throw new Error("Options must be provided");
    }
    const { socialName, shareData } = options;
    const fullLink = this._getFullShareLink({
      baseUrl: shareLinks[socialName],
      text: shareData.text,
      url: shareData.url,
    });
    if (fullLink) {
      window.open(fullLink, "_blank");
    }
  }
}
export default new SocialMediaShareService();
