<template>
  <div class="referrals-table__container">
    <div v-show="isScrollTopArrowVisible" class="referrals-table__scroll-top">
      <scroll-top-arrow @scrollToTop="scrollToTopHandler" />
    </div>
    <div
      @scroll="referralTableScrollHandler"
      ref="referralsTable"
      class="referrals-table"
    >
      <template v-for="(referral, index) of referralsList">
        <!--    TODO: can be wrapper into one <div class=table-row></div> tag    -->
        <Fragment :key="referral.id">
          <div
            :data-index="index"
            class="referrals-table__col data-col data-col__identifier"
          >
            <div class="referral-name">
              <div class="referral-name__logo">
                <svg
                  v-if="!referral.avatar"
                  width="32"
                  height="32"
                  viewBox="0 0 32 32"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <mask
                    id="mask0_11207_58703"
                    style="mask-type: alpha"
                    maskUnits="userSpaceOnUse"
                    x="0"
                    y="0"
                    width="32"
                    height="32"
                  >
                    <circle cx="16" cy="16" r="16" fill="white" />
                  </mask>
                  <g mask="url(#mask0_11207_58703)">
                    <circle
                      cx="16"
                      cy="16"
                      r="15.5"
                      fill="#B7C9D3"
                      stroke="#B7C9D3"
                    />
                    <circle cx="16" cy="16" r="15.5" stroke="#B7C9D3" />
                  </g>
                </svg>

                <img v-else :src="referral.avatar" alt="" />
              </div>
              <div class="referral-name__text">
                <span>{{ referral.account_number }}</span>
                <span class="referral-name__date">
                  {{ getFormattedDate(referral.created_at) }}
                </span>
                <span v-if="false" class="referral-name__status"> Новий </span>
              </div>
            </div>
          </div>
          <div :data-index="index" class="referrals-table__col data-col">
            <div class="referral-date">
              {{ getFormattedDate(referral.created_at) }}
            </div>
          </div>
          <div :data-index="index" class="referrals-table__col data-col">
            <div class="referral-labels"></div>
          </div>
          <div :data-index="index" class="referrals-table__col data-col">
            <referral-item-status :referral="referral"></referral-item-status>
          </div>
        </Fragment>
      </template>
    </div>
  </div>
</template>

<script>
import withdrawalIcon from "@/assets/img/referrals/table-referrals-ccons.svg";
import withdrawalIconSuccess from "@/assets/img/referrals/table-referrals-ccons-success.svg";
import Fragment from "@/components/base/Fragment";
import ScrollTopArrow from "@/components/base/ScrollTopArrow";
import EventBus from "@/event-bus";
import eventBusKeys from "@/const/eventBusKeys";
import moment from "moment";
import ReferralItemStatus from "@/components/referral/referralsTable/item/status/ReferralItemStatus";

export default {
  name: "ReferralTableUsersList",
  components: { ReferralItemStatus, ScrollTopArrow, Fragment },
  props: {
    referralsList: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      isScrollTopArrowVisible: false,
      withdrawalIcon,
      withdrawalIconSuccess,
    };
  },
  methods: {
    referralTableScrollHandler(event) {
      EventBus.$emit(eventBusKeys.referralTableScroll, event.target);
      this.$emit("referralTableScroll", event.target);
      this.isScrollTopArrowVisible = event.target.scrollTop >= 100;
    },
    scrollToTopHandler() {
      this.$refs.referralsTable?.scrollTo({ top: 0 });
    },
    getFormattedDate(date) {
      return moment(date).format("DD.MM.YY");
    },
  },
  watch: {
    referralsList: {
      immediate: true,
      handler() {
        this.scrollToTopHandler();
      },
    },
  },
};
</script>

<style scoped lang="scss">
@import "~@/assets/scss/_variables.scss";
@import "tableGrid.mixin";

.referrals-table {
  @include table-grid;
  grid-auto-rows: var(--row-height);
  height: 100%;
  overflow: auto;

  &::-webkit-scrollbar {
    width: 0;
  }

  &__scroll-top {
    display: none;
    position: absolute;
    bottom: 2rem;
    right: 1.5rem;
    z-index: 5;
  }

  &__container {
    position: relative;
    --row-height: 6rem /* 96/16 */;
    height: 100%;
    overflow: hidden;
  }

  &__col {
    @include colBorder;

    &:nth-child(4n - 3) {
      justify-content: flex-start;
    }

    &:nth-child(4n) {
      justify-content: flex-end;
    }
  }
}

.data-col {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.referral-name {
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  column-gap: 0.75rem /* 12/16 */;

  &__date,
  &__status {
    display: none;
  }

  &__text {
    overflow: hidden;

    span {
      color: $mainBlack;
      font-weight: 400;
      font-size: 1rem;
      line-height: 1.5rem;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }

  &__logo {
    display: flex;
    align-items: center;
  }

  &__status {
    font-family: "Nunito", "Rubik", sans-serif;
    font-weight: 700;
    font-size: 0.875rem;
    line-height: 1.25rem;
    color: $mainCold;
  }
}

.status-list {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: repeat(4, 1fr);

  &__item {
    position: relative;
  }
}

.status-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &:not(:last-child) {
    &::after {
      content: "";
      position: absolute;
      width: 50%;
      height: 1px;
      background-color: $gray3;
      right: -25%;
      top: calc(50% - 0.625rem);
    }
  }

  &__icon {
    width: 2rem;
    height: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__text {
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 1.125rem;
    color: $mainBlack;
  }
}

.withdrawal,
.withdrawal__icon {
  display: flex;
  align-items: center;
  justify-content: center;
}

.withdrawal {
  width: 100%;
  height: 100%;
  flex-direction: column;

  &--success {
    .withdrawal__status {
      color: $mainBlack;
    }
  }

  &__status {
    text-align: center;
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 1.125rem;
    color: $gray1;
  }
}

.mobile-steps {
  display: none;

  &__list {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  .steps-list {
    display: grid;
    grid-template-columns: repeat(4, 1.5rem);
    column-gap: 2rem;

    &__item {
      display: flex;
      align-items: center;
      width: 1.5rem;
      height: 1.5rem;

      ::v-deep {
        svg {
          color: $gray2;
          width: 100%;
          height: 100%;
        }
      }

      &--completed {
        ::v-deep {
          svg {
            color: $mainCold;
          }
        }
      }
    }
  }
}

.referral-name__date,
.referral-date {
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.25rem;
  color: $gray1;
}

@media screen and (max-width: 486px) {
  .referrals-table {
    column-gap: 0.625rem;

    &__scroll-top {
      display: block;
    }

    &__col {
      &:nth-child(4n -2),
      &:nth-child(4n -1) {
        display: none;
      }
    }

    &__container {
      --row-height: 6.875rem;
    }
  }
  .withdrawal {
    &__icon {
      margin-bottom: 0.375rem;

      & img {
        width: 1.5rem;
        height: 1.5rem;
      }
    }
  }
  .data-col {
    &__referral {
      display: grid;
      grid-auto-flow: row;
      justify-content: start;
    }
  }
  .mobile-steps {
    display: block;
  }
  .referral-name {
    column-gap: 0.8125rem;

    &__text {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }

    &__date,
    &__status {
      display: block;
    }

    &__logo {
      svg,
      img {
        width: 40px;
        height: 40px;
      }
    }
  }
}
</style>
