<template>
  <div class="referral-terms">
    <referral-program-terms-modal
      @closeModal="changeReferralProgramTermsVisibility(false)"
      v-if="isReferralProgramTermsOpen"
    />
    <div class="referral-terms__item">
      <personal-link-block
        @openDetails="changeReferralProgramTermsVisibility(true)"
        :referral-link="shareReferralLink"
      />
    </div>
  </div>
</template>

<script>
import PersonalLinkBlock from "@/components/referral/terms/PersonalLinkBlock";
import ReferralLinkMixin from "@/mixins/referral-link.mixin";
import ReferralProgramTermsModal from "@/components/referral/Popup/ReferralProgramTermsModal";

export default {
  name: "ReferralTerms",
  mixins: [ReferralLinkMixin],
  components: {
    ReferralProgramTermsModal,
    PersonalLinkBlock,
  },
  data() {
    return {
      isReferralProgramTermsOpen: false,
    };
  },
  methods: {
    changeReferralProgramTermsVisibility(value) {
      this.isReferralProgramTermsOpen = value;
    },
  },
};
</script>

<style scoped lang="scss">
.referral-terms {
  display: grid;
  grid-auto-flow: row;
  row-gap: 1rem;
}

@media screen and (max-width: 486px) {
  .referral-terms {
    &__item {
      margin: 0 -0.875rem;

      &:last-child {
        margin-bottom: 3.625rem;
      }
    }
  }
}
</style>
