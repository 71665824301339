<template>
  <div class="share-social">
    <ul class="share-social__list social-list">
      <template v-for="social of visibleSocial">
        <li
          @click="socialIconClickHandler(social.name)"
          :key="social.name"
          class="social-list__item"
        >
          <button type="button" :name="social.name">
            <img :src="social.icon" :alt="social.name" />
          </button>
        </li>
      </template>
    </ul>
  </div>
</template>

<script>
import ResponsiveMixin from "@/mixins/responsive-mixin";
import { rpInviteImages, socialMediaList } from "@/const/referrals";
import referralShareMixin from "@/mixins/referral-share.mixin";
import ReferralLinkMixin from "@/mixins/referral-link.mixin";

export default {
  name: "SocialList",
  mixins: [ResponsiveMixin, referralShareMixin, ReferralLinkMixin],
  data() {
    return {
      socialList: socialMediaList,
    };
  },
  computed: {
    visibleSocial() {
      return this.socialList.filter((social) =>
        this.isMobile ? social.visibleOnMobile : social.visibleOnDesktop
      );
    },
  },
  methods: {
    socialIconClickHandler(name) {
      this.shareInSocialMedia({
        socialName: name,
        referralLink: this.shareReferralLink,
        nativeShareOptions: {
          imagePath: rpInviteImages[this.$i18n.locale],
          fileName: "referral-partner-share.png",
        },
      });
    },
  },
};
</script>

<style scoped lang="scss">
@import "~@/assets/scss/_variables.scss";

.share-social {
  &__list {
    padding: 0;
    margin: 0;
    list-style: none;
  }
}

.social-list {
  display: grid;
  grid-auto-flow: column;
  justify-content: space-around;

  &__item,
  &__item button {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__item {
    width: 2.5rem;
    height: 2.5rem;

    button {
      width: 100%;
      height: 100%;
      padding: 0.625rem;
      cursor: pointer;
      border: 1px solid #dde5ed;
      transition: all 100ms ease-in;
      background: transparent;
      border-radius: 2.5rem;
    }

    &:hover button,
    &:active button {
      border: 1px solid transparent;
    }

    &:hover {
      button {
        box-shadow: 0 4px 32px rgba(121, 150, 160, 0.2);
        background: #ffffff;
      }
    }

    &:active {
      button {
        background: $grayLight;
      }
    }
  }
}
</style>
