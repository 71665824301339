<template>
  <div
    class="referral-status"
    :class="referralStatusItem.additionalClasses || []"
  >
    <div class="referral-status__main">
      <div
        class="referral-status__main-icon"
        v-if="referralStatusItem.icon"
        v-html="referralStatusItem.icon"
      ></div>
      <div class="referral-status__main-text">
        {{ $t(referralStatusItem.title) }}
      </div>
    </div>
    <div class="referral-status__hover">
      <div
        class="referral-status__hover-text"
        v-if="!referralStatusItem.stepsComponent"
      >
        {{ $t(referralStatusItem.hoverText) }}
      </div>
      <referral-item-status-steps
        v-else
        :account-id="referral.account_number"
        :tasks="referral.tasks"
      ></referral-item-status-steps>
    </div>
  </div>
</template>

<script>
import { referralStatusIcons } from "@/const/referrals";
import ReferralItemStatusSteps from "@/components/referral/referralsTable/item/status/ReferralItemStatusSteps";
export default {
  name: "ReferralItemStatus",
  components: { ReferralItemStatusSteps },
  props: {
    referral: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      availableTasksStatuses: [
        {
          slug: "nothing-complete",
          title: "referrals_page.table.status.registration-pending.main-text",
          icon: referralStatusIcons["registration-pending"],
          additionalClasses: [],
          stepsComponent: true,
        },
        {
          slug: "registration-complete",
          title: "referrals_page.table.status.registration-complete.main-text",
          icon: referralStatusIcons["registration-complete"],
          additionalClasses: [],
          stepsComponent: true,
        },
        {
          slug: "card-created",
          title: "referrals_page.table.status.card-created.main-text",
          icon: referralStatusIcons["card-created"],
          additionalClasses: [],
          stepsComponent: true,
        },
        {
          slug: "wallet-added",
          title: "referrals_page.table.status.wallet-added.main-text",
          icon: referralStatusIcons["wallet-added"],
          additionalClasses: [],
          stepsComponent: true,
        },
        {
          slug: "transaction-complete",
          title: "referrals_page.table.status.transaction-complete.main-text",
          icon: referralStatusIcons["transaction-complete"],
          additionalClasses: [],
          stepsComponent: true,
        },
      ],
      availableTransactionStatuses: [
        {
          slug: "award-pending",
          title: "referrals_page.table.status.award-pending.main-text",
          icon: referralStatusIcons["award-pending"],
          additionalClasses: ["referral-status--award-pending"],
          hoverText: "referrals_page.table.status.award-pending.hover-text",
        },
        {
          slug: "award-received",
          title: "referrals_page.table.status.award-received.main-text",
          icon: referralStatusIcons["award-received"],
          additionalClasses: ["referral-status--award-received"],
          hoverText: "referrals_page.table.status.award-received.hover-text",
        },
        {
          slug: "award-withdraw",
          title: "referrals_page.table.status.award-withdraw.main-text",
          icon: referralStatusIcons["award-withdraw"],
          additionalClasses: ["referral-status--award-withdraw"],
          hoverText: "referrals_page.table.status.award-withdraw.hover-text",
        },
      ],
    };
  },
  computed: {
    referralStatusItem() {
      if (this.referral.roles.includes("referral")) {
        return this.currentTaskStatus;
      } else {
        return this.currentTransactionStatus;
      }
    },
    currentTaskStatus() {
      let _completeTask = [...this.referral.tasks]
        .sort((t1, t2) => (t1.ord > t2.ord ? -1 : t1.ord === t2.ord ? 0 : 1))
        .find((t) => t.status === "complete");

      if (!_completeTask) {
        return this.availableTasksStatuses.find(
          (_aTS) => _aTS.slug === "nothing-complete"
        );
      }

      return this.availableTasksStatuses.find(
        (_aTS) => _aTS.slug === _completeTask.referral_task_slug
      );
    },
    currentTransactionStatus() {
      if (!this.referral.referral_transaction) {
        return this.availableTransactionStatuses.find(
          (_aTS) => _aTS.slug === "award-pending"
        );
      } else if (
        this.referral.referral_transaction &&
        this.referral.referral_transaction.status === "pending"
      ) {
        return this.availableTransactionStatuses.find(
          (_aTS) => _aTS.slug === "award-received"
        );
      } else if (
        this.referral.referral_transaction &&
        this.referral.referral_transaction.status === "complete"
      ) {
        return this.availableTransactionStatuses.find(
          (_aTS) => _aTS.slug === "award-withdraw"
        );
      } else {
        return this.availableTransactionStatuses.find(
          (_aTS) => _aTS.slug === "award-pending"
        );
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/_variables.scss";
@import "~@/assets/scss/_mixins.scss";

.referral-status {
  position: relative;
  height: 40px;
  width: 100%;

  background-color: #f4f8fc;
  border-radius: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: default;

  &:hover & {
    &__main {
      opacity: 0;
    }

    &__hover {
      opacity: 1;
    }
  }

  &__main {
    display: flex;
    align-items: center;
    justify-content: center;
    transition: opacity 0.2s linear;

    &-icon {
      margin-right: 8px;
      width: 20px;
      height: 20px;

      display: flex;
      align-items: center;
      justify-content: center;

      ::v-deep {
        svg {
          max-width: 100%;
          max-height: 100%;

          path {
            fill: $gray1;
          }
        }
      }
    }

    &-text {
      color: $gray1;
      font-size: pxToRem(14);
      line-height: pxToRem(20);
    }
  }

  &__hover {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    opacity: 0;
    transition: opacity 0.2s linear;

    &-text {
      color: $gray1;
      font-size: pxToRem(14);
      line-height: pxToRem(20);
    }
  }

  &--award-pending {
    background-color: $gray2;
  }

  &--award-pending & {
    &__main {
      &-text {
        color: #ffffff;
      }

      &-icon {
        ::v-deep {
          svg {
            path {
              fill: #ffffff;
            }
          }
        }
      }
    }
    &__hover {
      &-text {
        color: #ffffff;
      }
    }
  }

  &--award-received {
    background-color: $mainCold;
  }

  &--award-received & {
    &__main {
      &-text {
        color: #ffffff;
      }

      &-icon {
        ::v-deep {
          svg {
            path {
              fill: #ffffff;
            }
          }
        }
      }
    }

    &__hover {
      &-text {
        color: #ffffff;
      }
    }
  }

  &--award-withdraw {
    background-color: $mainColdLight;
  }

  &--award-withdraw & {
    &__main {
      &-text {
        color: $mainCold;
      }

      &-icon {
        ::v-deep {
          svg {
            path {
              fill: $mainCold;
            }
          }
        }
      }
    }
    &__hover {
      &-text {
        color: $mainCold;
      }
    }
  }
}
</style>
