<template>
  <div class="referral-terms popup">
    <div class="referral-terms__content terms-content popup__block">
      <div class="terms-content__top terms-top">
        <div class="terms-top__title terms-title">
          {{ $t("referral_terms_modal.title") }}
        </div>
        <div class="terms-top__subtitle terms-subtitle">
          {{ $t("referral_terms_modal.subtitle") }}
        </div>
      </div>
      <ul class="terms-content__list terms-list">
        <li class="terms-list__item terms-item">
          <div class="terms-item__logo">
            <img
              src="@/assets/img/referrals/terms/registration.png"
              alt="Registration"
            />
          </div>
          <div class="terms-item__text">
            {{ $t("referral_terms_modal.terms.register") }}
          </div>
        </li>
        <li class="terms-list__item terms-item">
          <div class="terms-item__logo">
            <img src="@/assets/img/referrals/terms/card.png" alt="Card" />
          </div>
          <div class="terms-item__text">
            {{ $t("referral_terms_modal.terms.card") }}
          </div>
        </li>
        <li class="terms-list__item terms-item">
          <div class="terms-item__logo">
            <img
              src="@/assets/img/referrals/terms/exchange.png"
              alt="Connect exchange"
            />
          </div>
          <div class="terms-item__text">
            {{ $t("referral_terms_modal.terms.connect_exchange") }}
          </div>
        </li>
        <li class="terms-list__item terms-item">
          <div class="terms-item__logo">
            <img
              src="@/assets/img/referrals/how-to-transaction/coins.png"
              alt="Transaction"
            />
          </div>
          <div class="terms-item__text">
            {{ $t("referral_terms_modal.terms.transaction") }}
          </div>
        </li>
      </ul>
      <div class="terms-content__action terms-action">
        <primary-colored-button @click="closeModal">
          {{ $t("referral_terms_modal.action") }}
        </primary-colored-button>
      </div>
    </div>
  </div>
</template>

<script>
import PrimaryColoredButton from "@/components/button/PrimaryColoredButton";

export default {
  name: "ReferralProgramTermsModal",
  components: { PrimaryColoredButton },
  methods: {
    closeModal() {
      this.$emit("closeModal");
    },
  },
};
</script>

<style scoped lang="scss">
@import "~@/assets/scss/_variables.scss";

.referral-terms {
  &__content {
    max-width: 36.4375rem;
  }
}

.terms-title,
.terms-subtitle,
.terms-item__text {
  color: $mainBlack;
}

.terms-title,
.terms-subtitle {
  font-family: "Rubik", sans-serif;
  text-align: center;
}

.terms-title,
.terms-item__text {
  font-weight: 700;
}

.terms-content {
  &__top {
    margin-bottom: 1.5rem;
  }

  &__list {
    padding: 0;
    list-style: none;
    margin: 0 0 2.5rem 0;
  }
}

.terms-top {
  display: grid;
  grid-auto-flow: row;
  row-gap: 2.5rem;
}

.terms-title {
  font-size: 1.5rem;
  line-height: 2rem;
}

.terms-subtitle {
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5rem;
}

.terms-list {
  display: grid;
  grid-template-rows: repeat(4, 1fr);
  row-gap: 1rem;

  &__item {
    background: #f4f8fc;
    border-radius: 1rem;
    padding: 1rem 1.5rem;
  }
}

.terms-item {
  --image-width: 3rem;

  display: grid;
  grid-template-columns: var(--image-width) 1fr;
  column-gap: 1.5rem;
  align-items: center;

  &__text {
    font-family: "Nunito", "Rubik", sans-serif;
    font-size: 1rem;
    line-height: 1.5rem;
  }

  &__logo {
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      max-width: 100%;
    }
  }
}

@media screen and (max-width: 486px) {
  .referral-terms {
    &__content {
      max-width: 100%;
      display: flex;
      flex-direction: column;
      padding-bottom: 5rem;
    }
  }
  .terms-action {
    margin-top: auto;
  }
  .terms-top {
    row-gap: 1.875rem;
  }
  .terms-title {
    font-size: 1.25rem;
    line-height: 1.75rem;
  }

  .terms-list {
    &__item {
      padding: 1rem 1.25rem;
    }
  }
  .terms-item {
    --image-width: 2rem;
  }
}
</style>
