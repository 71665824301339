<template>
  <div>
    <referral-withdrawal-modal
      @confirmWithdrawal="confirmWithdrawalHandler"
      @closeModal="changeWithdrawModalVisibility(false)"
      v-if="isWithdrawalModalOpen"
      :amount="withdrawalAmount"
    />
    <div v-show="isPaymentsVisible" class="payments-wrapper">
      <payments-section :payments-data="paymentsData" />
    </div>
    <div class="referral-content">
      <referral-terms />
      <referrals-list ref="referralsListRef" />
    </div>
  </div>
</template>

<script>
import PaymentsSection from "@/components/referral/PaymentsSection";
import EventBus from "@/event-bus";
import eventBusKeys from "@/const/eventBusKeys";

import availableLogo from "@/assets/img/referrals/partner/available.svg";
import waitingLogo from "@/assets/img/referrals/partner/waiting.svg";
import receivedLogo from "@/assets/img/referrals/partner/recieved.svg";
import referralLinkMixin from "@/mixins/referral-link.mixin";
import ReferralsList from "@/views/Referrals/MyReferrals";
import ReferralTerms from "@/views/Referrals/ReferralTerms";
import ReferralWithdrawalModal from "@/components/referral/Popup/ReferralWithdrawalModal";
import { ReferralsApi } from "@/office/referrals-api";
import store from "@/store";
import { SET_SUPPORT_LINK } from "@/store/const/actions-types";

export default {
  name: "ReferralPartner",
  mixins: [referralLinkMixin],
  components: {
    ReferralWithdrawalModal,
    ReferralTerms,
    ReferralsList,
    PaymentsSection,
  },
  data() {
    return {
      withdrawalAmount: 12,
      isWithdrawalModalOpen: false,
      isDataLoading: true,
      isPaymentsVisible: true,
      paymentsData: {
        available_to_withdraw: {
          value: 0,
          currency: "USDT",
          description: "referral_partner.payments.available.text",
          descriptionData: {
            date: "01.12",
          },
          tooltip: "referral_partner.payments.available.tooltip",
          tooltipData: {
            date: "01.12.2023",
          },
          logoPath: availableLogo,
        },
        wait_to_withdraw: {
          value: 0,
          currency: "USDT",
          description: "referral_partner.payments.waiting.text",
          tooltip: "referral_partner.payments.waiting.tooltip",
          logoPath: waitingLogo,
        },
        complete_withdraw: {
          value: 0,
          currency: "USDT",
          description: "referral_partner.payments.received.text",
          tooltip: "referral_partner.payments.received.tooltip",
          logoPath: receivedLogo,
        },
      },
    };
  },
  async created() {
    store.dispatch(SET_SUPPORT_LINK, "ref_page");

    this.isDataLoading = true;
    await this.loadIndicators();
    this.isDataLoading = false;
  },
  inject: ["showSuccessTip"],
  computed: {
    pageTitle() {
      return this.$t(this.$route.meta.pageTitle) || "";
    },
  },
  methods: {
    changeWithdrawModalVisibility(value) {
      this.isWithdrawalModalOpen = value;
    },
    referralTableScrollHandler(event) {
      this.isPaymentsVisible = event.scrollTop < 100;
    },
    async loadReferralsData() {
      await Promise.all([
        this.loadIndicators(),
        this.$refs.referralsListRef.loadReferralsList(),
      ]);
    },
    async confirmWithdrawalHandler() {
      try {
        const response = await ReferralsApi.submitWithdrawal();
        if (!response.success) {
          console.error("Can't submit withdrawal");
          return;
        }
        await this.loadReferralsData();
        this.showSuccessTip(this.$t("referral_withdrawal_modal.notify"));
        this.changeWithdrawModalVisibility(false);
      } catch (error) {
        if (error instanceof Error) {
          console.error(error.message);
        }
      }
    },

    async loadIndicators() {
      try {
        const _indicatorsResponse = (await ReferralsApi.getIndicators()).data;

        this.paymentsData.available_to_withdraw.value =
          parseInt(_indicatorsResponse.available_to_withdraw) / 100;
        this.paymentsData.wait_to_withdraw.value =
          parseInt(_indicatorsResponse.wait_to_withdraw) / 100;
        this.paymentsData.complete_withdraw.value =
          parseInt(_indicatorsResponse.complete_withdraw) / 100;
      } catch (e) {
        console.error("Something went wrong when loading indicators: \n", e);
      }
    },
  },
  mounted() {
    EventBus.$on(
      eventBusKeys.referralTableScroll,
      this.referralTableScrollHandler
    );
  },
};
</script>

<style lang="scss" scoped>
.content-wrapper {
  margin-top: 3rem;
  padding: 0 2rem;
}

.payments-wrapper {
  margin-bottom: 1.5rem;
}

.referral-content {
  display: grid;
  grid-auto-flow: row;
  row-gap: 1.5rem;

  &__navigation {
    margin-top: 2.5rem;
    margin-bottom: 1rem;
  }
}

@media screen and (max-width: 486px) {
  .content-wrapper {
    margin-top: 2.0625rem;
    padding: 0;
  }
}
</style>
