<template>
  <div class="scroll-arrow" @click="clickHandler">
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.70711 15.7071C6.31658 16.0976 5.68342 16.0976 5.29289 15.7071C4.90237 15.3166 4.90237 14.6834 5.29289 14.2929L6.70711 15.7071ZM12 9L11.2929 8.29289L12 7.58579L12.7071 8.29289L12 9ZM18.7071 14.2929C19.0976 14.6834 19.0976 15.3166 18.7071 15.7071C18.3166 16.0976 17.6834 16.0976 17.2929 15.7071L18.7071 14.2929ZM5.29289 14.2929L11.2929 8.29289L12.7071 9.70711L6.70711 15.7071L5.29289 14.2929ZM12.7071 8.29289L18.7071 14.2929L17.2929 15.7071L11.2929 9.70711L12.7071 8.29289Z"
        fill="#7A939C"
      />
    </svg>
  </div>
</template>

<script>
export default {
  name: "ScrollTopArrow",
  methods: {
    clickHandler() {
      this.$emit("scrollToTop");
    },
  },
};
</script>

<style scoped lang="scss">
.scroll-arrow {
  width: 48px;
  height: 48px;
  background-color: #fff;
  filter: drop-shadow(0px 0px 16px rgba(183, 201, 211, 0.5));
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: 100%;
  z-index: 5;
}
</style>
