class NativeShareService {
  _navigatorAvailable = false;

  constructor() {
    this._navigatorAvailable = navigator?.share !== undefined;
  }

  /**
   *
   * @param {{title: string, text: string, url?: string, files?: File[]}} data
   * @returns {Promise<boolean>}
   */
  async shareData(data) {
    if (!this._navigatorAvailable) {
      console.error("Navigator is not available");
      return false;
    }
    try {
      const canShare = navigator.canShare({ ...data });
      if (!canShare) {
        console.error("Can't share", JSON.stringify(data, null, 2));
        return false;
      }
      await navigator.share({ ...data });
      return true;
    } catch (error) {
      console.error("Native share error: \n", error);
      return false;
    }
  }
}

export default new NativeShareService();
