import { NativeShareService, SocialMediaShareService } from "@/services/share";
import { imageToFile } from "@/utils";
import { messengersShareText, shareText } from "@/const/referrals";

export default {
  methods: {
    getTextForSocial(socialName) {
      switch (socialName) {
        case "native":
          return messengersShareText[this.$i18n.locale] + "\n";
        case "whatsapp":
        case "reddit":
          return shareText[this.$i18n.locale][0];
        case "twitter":
        case "facebook":
        case "telegram":
        default:
          return shareText[this.$i18n.locale].join("\n\n");
      }
    },
    /**
     * @param {{socialName: string, referralLink: string, text?: string, nativeShareOptions?: {imagePath: string, fileName: string}}} options
     */
    async shareInSocialMedia(options) {
      if (!options) {
        return console.error("options must be provided");
      }
      try {
        const { socialName, referralLink, nativeShareOptions } = options;
        const socialMediaText = this.getTextForSocial(socialName);

        if (socialName === "native") {
          /**
           * @type {ShareData}
           */
          const shareDataPayload = {
            title: "Weld Money",
            text: socialMediaText + referralLink,
          };

          const { fileName, imagePath } = nativeShareOptions;
          if (imagePath && fileName) {
            const file = await imageToFile({
              imagePath,
              fileName,
            });
            if (file !== null) {
              shareDataPayload.files = [file];
            }
          }

          await NativeShareService.shareData({ ...shareDataPayload });
          return;
        }
        if (socialName === "download") {
          return;
        }
        SocialMediaShareService.shareData({
          socialName,
          shareData: {
            url: referralLink,
            text: socialMediaText,
          },
        });
      } catch (error) {
        console.error(error);
      }
    },
  },
};
