<template>
  <div class="personal-link-block">
    <div class="personal-link-block__list block-list">
      <div class="block-list__item invite-block">
        <span class="invite-block__title">
          {{ $t("referral_partner.terms.partner.title") }}
        </span>
        <div class="invite-block__content invite-content">
          <span class="invite-content__title">
            {{ $t("referral_partner.terms.partner.description") }}
          </span>
          <div class="invite-content__action">
            <button
              @click="detailsClickHandler"
              type="button"
              class="invite-content__button"
            >
              {{ $t("referral_partner.terms.partner.details") }}
            </button>
          </div>
        </div>
      </div>
      <div class="block-list__item block-item">
        <div class="block-item__title">
          {{ $t("referral_partner.terms.personal_link.title") }}
        </div>
        <div class="block-item__content">
          <share-referral-link :referral-link="referralLink" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ShareReferralLink from "@/components/referral/ShareReferralLink";

export default {
  name: "PersonalLinkBlock",
  components: { ShareReferralLink },
  props: {
    referralLink: {
      type: String,
      required: true,
    },
  },
  inject: ["showSuccessTip"],
  methods: {
    detailsClickHandler() {
      this.$emit("openDetails");
    },
  },
};
</script>

<style scoped lang="scss">
@import "~@/assets/scss/_variables.scss";

.personal-link-block {
  background: #ffffff;
  border-radius: 1.5rem;
  padding: 2.5rem;
  --block-list-gap: 2.5rem;
}

.block-list {
  display: grid;
  grid-template-columns: 40% 1fr;
  column-gap: calc(var(--block-list-gap) * 2);
  height: 100%;

  &__item {
    &:first-child {
      position: relative;

      &::after {
        box-sizing: border-box;
        content: "";
        position: absolute;
        left: calc(100% + var(--block-list-gap));
        top: 0;
        height: 100%;
        width: 1px;
        background: $dividersGray;
        @media screen and (max-width: 486px) {
          right: 0;
          left: 0;
          top: calc(100% + var(--block-list-gap));
          bottom: 0;
          width: 100%;
          height: 1px;
        }
      }
    }
  }
}

.block-item__title,
.invite-block__title {
  color: $mainBlack;
  font-weight: 600;
}

.block-item__title,
.invite-block__title,
.invite-content__title,
.invite-content__button {
  font-family: "Rubik", sans-serif;
}

.block-item {
  display: flex;
  flex-direction: column;
  height: 100%;

  &:first-child {
    position: relative;

    &::after {
      content: "";
      position: absolute;
      right: calc(var(--block-list-gap) - (2 * var(--block-list-gap)));
      top: 0;
      height: 100%;
      border: 1px dashed $gray2;
    }
  }

  &__title,
  &__description {
    font-size: 1rem;
    line-height: 1.5rem;
  }

  &__title {
    margin-bottom: 1rem;
  }

  &__description {
    font-weight: 500;
    color: $gray1;

    b {
      font-weight: 700;
    }
  }
}

.invite-block,
.invite-content {
  display: grid;
  grid-auto-flow: row;
}

.invite-block {
  row-gap: 1.5rem;
  height: fit-content;

  &__title {
    font-size: 1.25rem;
    line-height: 1.75rem;
  }
}

.invite-content {
  row-gap: 0.5rem;

  &__title {
    color: $gray1;
    font-size: 1rem;
    line-height: 1.5rem;
    font-weight: 400;
  }

  &__button {
    background: transparent;
    padding: 0;
    border: none;
    font-weight: 600;
    font-size: 1rem;
    line-height: 1.5rem;
    color: $mainCold;
    cursor: pointer;
  }
}

.limit-card-info {
  width: 100%;
  padding: 12px 20px;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #eb6fbd;
  background: #fbe2f2;
  border-radius: 16px;
}

@media screen and (max-width: 486px) {
  .personal-link-block {
    padding: 2.5rem 1rem;
    min-height: auto;

    --block-list-gap: 2rem;
  }

  .block-list {
    grid-template-columns: auto;
    grid-auto-flow: row;
    row-gap: calc(var(--block-list-gap) * 2);
  }

  .block-item {
    justify-content: flex-start;

    &__title {
      margin-bottom: 1.5rem;
    }

    &__description {
      max-width: 80%;
    }

    &:last-child {
      .block-item {
        &__title {
          margin-bottom: 1rem;
        }
      }
    }

    &:first-child {
      &::after {
        display: none;
      }

      &::before {
        content: "";
        position: absolute;
        bottom: calc(var(--block-list-gap) - (2 * var(--block-list-gap)));
        left: 0;
        right: 0;
        width: 100%;
        height: 1px;
        border-bottom: 1px dashed $gray2;
      }
    }
  }

  .invite-block {
    row-gap: 1rem;
    &__title {
      font-size: 1rem;
      line-height: 1.5rem;
    }
  }
  .invite-content {
    &__title {
      font-family: "Nunito", sans-serif;
    }
  }
}
</style>
