<template>
  <div class="referral-per-page">
    <span class="referral-per-page__text">
      {{
        $t(
          "referral_registration.my_referrals.pagination.per_page_count.look_by"
        )
      }}
    </span>
    <referrals-per-page-dropdown
      @select="selectHandler"
      :data-list="referralPerPageList"
      :selected-value="selectedReferralsPerPage"
    />
    <span class="referral-per-page__text">
      {{
        $t(
          "referral_registration.my_referrals.pagination.per_page_count.referral_per_page"
        )
      }}
    </span>
  </div>
</template>

<script>
import ReferralsPerPageDropdown from "@/components/referral/referralsTable/pagination/ReferralsPerPageDropdown";

export default {
  name: "ReferralsPerPage",
  props: {
    selectedReferralsPerPage: {
      type: Number,
      required: true,
    },
    referralPerPageList: {
      type: Array,
      required: true,
    },
  },
  components: { ReferralsPerPageDropdown },
  methods: {
    selectHandler(value) {
      this.$emit("selectReferralsPerPage", value);
    },
  },
};
</script>

<style scoped lang="scss">
@import "~@/assets/scss/_variables.scss";

.referral-per-page {
  position: relative;
  display: grid;
  grid-auto-flow: column;
  column-gap: 1.25rem;
  align-items: center;

  &__text {
    font-family: "Nunito", "Rubik", sans-serif;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.5rem;
    color: $gray1;
  }
}
@media screen and (max-width: 486px) {
  .referral-per-page {
    height: 4.0625rem;
    align-items: center;
    display: flex;
    justify-content: space-between;
    column-gap: 1.75rem;

    &__text {
      font-size: 0.875rem;
      line-height: 1.125rem;
    }
  }
}
</style>
