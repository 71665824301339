<template>
  <div class="payment-block" :class="{ 'payment-block--first': index === 0 }">
    <div class="payment-block__text text-block">
      <div class="text-block__value">
        {{ value }}
      </div>
      <div class="text-block__description description">
        <span class="description__text">
          {{ description }}
        </span>
        <div v-if="!!tooltip" class="description__tooltip">
          <v-popover
            placement="top"
            offset="3"
            trigger="manual"
            :open="isTooltipOpen"
            popoverClass="payment-block__popover"
            class="title-popover"
            @auto-hide="closeTooltipHandler"
          >
            <svg
              @click="toggleTooltipVisibility"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="currentColor"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12.1379 9.00051C12.6624 9.00051 13.1034 8.5497 13.1034 8.01123C13.1034 7.44772 12.6505 7.03448 12.1379 7.03448C11.6254 7.03448 11.1724 7.44772 11.1724 8.01123C11.1724 8.56222 11.6135 9.00051 12.1379 9.00051Z"
                fill="currentColor"
              />
              <path
                d="M11.2916 15.8503C11.2916 16.1884 11.5062 16.4138 11.828 16.4138H12.4598C12.7816 16.4138 12.9962 16.1884 12.9962 15.8503V10.4281C12.9962 10.09 12.7816 9.86455 12.4598 9.86455H11.828C11.5062 9.86455 11.2916 10.09 11.2916 10.4281V15.8503Z"
                fill="currentColor"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M20 12C20 16.4183 16.4183 20 12 20C7.58172 20 4 16.4183 4 12C4 7.58172 7.58172 4 12 4C16.4183 4 20 7.58172 20 12ZM18.8966 12C18.8966 15.8089 15.8089 18.8966 12 18.8966C8.19114 18.8966 5.10345 15.8089 5.10345 12C5.10345 8.19114 8.19114 5.10345 12 5.10345C15.8089 5.10345 18.8966 8.19114 18.8966 12Z"
                fill="currentColor"
              />
            </svg>

            <template v-slot:popover>
              <tooltip-content-with-button @closeTooltip="closeTooltipHandler">
                <span>{{ tooltip }}</span>
              </tooltip-content-with-button>
            </template>
          </v-popover>
        </div>
      </div>
    </div>
    <div class="payment-block__logo logo-block">
      <img :src="imagePath" :alt="description" />
    </div>
  </div>
</template>

<script>
import TooltipContentWithButton from "@/components/base/tooltip/TooltipContentWithButton";

export default {
  name: "PaymentBlock",
  components: {
    TooltipContentWithButton,
  },
  props: {
    description: {
      type: String,
      required: true,
    },
    value: {
      type: String,
      required: true,
    },
    imagePath: {
      type: String,
      required: true,
    },
    tooltip: {
      type: String,
    },
    index: {
      type: [Number, String],
      required: true,
    },
  },
  data() {
    return {
      isTooltipOpen: false,
    };
  },
  methods: {
    toggleTooltipVisibility() {
      this.isTooltipOpen = !this.isTooltipOpen;
    },
    closeTooltipHandler() {
      this.isTooltipOpen = false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/_variables.scss";

.payment-block {
  display: grid;
  grid-auto-flow: column;
  column-gap: 1.0625rem;
  justify-content: space-between;

  &--first .text-block__value {
    font-weight: 700;
  }
}

.text-block__value,
.description__text {
  font-family: "Rubik", sans-serif;
}

.text-block {
  display: grid;
  grid-auto-flow: row;
  row-gap: 0.5rem;

  &__value {
    font-weight: 400;
    font-size: 1.875rem;
    line-height: 2.25rem;
    color: $mainBlack;
  }
}

.description {
  display: grid;
  grid-auto-flow: column;
  column-gap: 0.25rem;
  align-items: center;
  width: fit-content;

  &__text {
    font-weight: 400;
    font-size: 1rem;
    line-height: normal;
    color: $gray1;
  }

  &__tooltip {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    svg {
      color: $gray1;
    }
  }
}

@media screen and (max-width: 486px) {
  .payment-block {
    padding: 1rem 0;
    align-items: center;
  }
  .text-block {
    &__value {
      font-size: 1.5rem;
      line-height: 2rem;
    }
  }
  .description {
    align-items: flex-start;
    &__text {
      font-size: 1rem;
      line-height: 1.5rem;
    }
    &__tooltip {
      svg {
        color: $mainCold;
      }
    }
  }
}
</style>

<style lang="scss">
.description__tooltip .v-popover .trigger {
  display: flex !important;
  align-items: center;
  justify-content: center;
}
.payment-block__popover {
  .tooltip-inner {
    max-width: 288px;
  }
}
</style>
