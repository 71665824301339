<template>
  <div class="table-pagination__wrapper">
    <div class="table-pagination__count-control">
      <referrals-per-page
        :referral-per-page-list="referralsPerPageDataList"
        :selected-referrals-per-page="selectedReferralsPerPage"
        @selectReferralsPerPage="selectReferralsPerPageHandler"
      />
    </div>
    <div class="table-pagination__page-navigation">
      <referral-table-navigation
        @changePage="changePageHandler"
        :current-page="currentPage"
        :total-items-count="totalItemsCount"
        :referrals-per-page="selectedReferralsPerPage"
      />
    </div>
  </div>
</template>

<script>
import ReferralsPerPage from "@/components/referral/referralsTable/pagination/ReferralsPerPage";
import ReferralTableNavigation from "@/components/referral/referralsTable/navigation/ReferralTableNavigation";

export default {
  name: "ReferralTablePagination",
  components: { ReferralTableNavigation, ReferralsPerPage },
  props: {
    currentPage: {
      type: Number,
      required: true,
    },
    selectedReferralsPerPage: {
      type: Number,
      required: true,
    },
    referralsPerPageDataList: {
      type: Array,
      required: true,
    },
    totalItemsCount: {
      type: Number,
      required: true,
    },
  },
  methods: {
    selectReferralsPerPageHandler(value) {
      this.$emit("selectReferralsPerPage", value);
    },
    changePageHandler(page) {
      this.$emit("changePage", page);
    },
  },
};
</script>

<style scoped lang="scss">
.table-pagination {
  &__wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    padding: 0.4375rem 0 0.5rem 0;
  }
}

@media screen and (max-width: 486px) {
  .table-pagination {
    &__wrapper {
      flex-direction: column-reverse;
      justify-content: center;
      padding: 0 0 1rem;
    }

    &__page-navigation {
      margin-bottom: 0.5rem;
    }

    &__count-control,
    &__page-navigation {
      width: 100%;
    }
  }
}
</style>
